


























































































































































































































import { Values } from 'vue-i18n';
import { Vue, Component } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { selectAllOnClick, store } from '../common';
import { Overlay } from '../components';
import { SingleOrgField } from '../organizations';
import { Comp, Dict, Org, Position, Version } from '../types';
import I18n from '../I18n';
import { SingleVersionField } from '../versions';
import CivilPositionCell from './CivilPositionCell.vue';
import EduPositionSingleCell from './EduPositionSingleCell.vue';
import MvdPositionCell from './MvdPositionCell.vue';
import PositionsSelection from './PositionSelection.vue';
import PositionCell from './PositionCell.vue';


// region Local types
/** Строка таблицы */
interface IRow {
    /**
     * ID
     *
     * Или ID сохраненной записи, или уникальная строка для только что созданной
     */
    id: string;

    /** Изменяемые данные */
    data: Position;

    /** С этими данными сравнивается `data` для определения изменений */
    original?: Position;

    /** Признак "строка изменена" */
    changed: boolean;

    /** Признак "строка некорректна" */
    invalid: boolean;

    /** Варианты отображения строки в таблице */
    _rowVariant?: 'primary' | 'info' | 'success' | 'danger';

    /** Ключи переводов ошибок для полей */
    errors: {
        /** Ошибка поля "Название на казахском" */
        nameKk?: string;

        /** Ошибка поля "Название на русском" */
        nameRu?: string;

        /** Ошибка поля "Уровень должности" */
        posLevel?: string;

        /** Ошибка поля "Должность по НПА" */
        legalActPosition?: string;

        /** Ошибка поля "Гражданская должность" */
        civilPosition?: string;

        /** Ошибка поля "Мвд должность" */
        mvdPosition?: string;

        /** Ошибка поля "Должность по НПА для сотрудников в Мвд " */
        legalActMvdPosition?: string;

        /** Ошибка поля "Квалификационный разряд рабочей должности" */
        workPositionRank?: string;

        /** Ошибка поля "Должность организации образования" */
        eduPosition?: string;
    };

    /**
     * Введенные пользователем значения
     *
     * _Используются, чтоб не терялись ошибки_
     */
    inputValues: {
        /** Текстовое значение поля "Уровень должности" */
        posLevel?: string;
    };
}

/** Изменение строки */
interface IChange {
    /** Изменение поля "Название на казахском" */
    nameKk?: string;

    /** Изменение поля "Название на русском" */
    nameRu?: string;

    /** Изменение поля "Функциональный блок" */
    funcBlock?: Dict.Position.FunctionalBlock;

    /** Изменение поля "Уровень должности" */
    posLevel?: string;

    /** Изменение поля "Должность по НПА" */
    legalActPosition?: Dict.Position;

    /** Изменение поля "Гражданская должность" */
    civilPosition?: Dict.CivilPosition;

    /** Изменение поля "Мвд должность" */
    mvdPosition?: Dict.MvdPosition;

    /** Изменение поля "Должность по НПА для сотрудников в Мвд" */
    legalActMvdPosition?: Dict.Position;

    /** Изменение поля "Квалификационный разряд рабочей должности" */
    workPositionRank?: number | null;

    /** Изменение поля "Должность организации образования" */
    eduPosition?: Dict.EduPosition;
}
// endregion


// region Utils
const functionalBlocks: Dict.Position.FunctionalBlock[] = ['SCS', 'A', 'B', 'C'];
// endregion


@Component({
    components: {
        CivilPositionCell,
        EduPositionSingleCell,
        MvdPositionCell,
        Overlay,
        PositionCell,
        PositionsSelection,
        SingleOrgField,
        SingleVersionField,
    }
})
export default class Page extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('rowsRenderingTimeoutId', (n: number | null, o: number | null) => {
            if (o !== null) {
                clearTimeout(o);
            }
        });

        this.$watch('selectedOrg', (selectedOrg: Org | null) => {
            store.org = selectedOrg;
            store.conditionExp = null
            this.selectedVersion = null;
        });

        this.$watch('selectedVersion', (selectedVersion: Version | null) => {
            store.version = selectedVersion;
            store.gaDepartmentList = []
            store.subDepartments = []
            if (selectedVersion === null) {
                this.rows = [];
            } else {
                this.reload();
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (this.selectedVersion !== null) this.reload();
    }
    // endregion


    // region Утилиты
    private i18n = new I18n('modules.budget.staffing_table.ga_department_positions');

    private static copyPosition(position: Position): Position {
        return {
            id: position.id,
            nameKk: position.nameKk,
            nameRu: position.nameRu,
            funcBlock: position.funcBlock,
            posLevel: position.posLevel,
            legalActPosition: position.legalActPosition,
            civilPosition: position.civilPosition,
            mvdPosition: position.mvdPosition,
            legalActMvdPosition: position.legalActMvdPosition,
            workPositionRank: position.workPositionRank,
            eduPosition: position.eduPosition,
            version: position.version,
        };
    }

    private getTranslate(key: string, values?: Values): string {
        return this.i18n.translate(key, values);
    }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private selectAllOnClick = selectAllOnClick;
    // endregion


    // region Строки таблицы
    private get fields(): Comp.TableFieldDef[] {
        const guCode: string = ((): string => {
            const gu = this.selectedOrg?.gu ?? null
            return gu?.code ?? ''
        })();

        let abpCode: string;
        if (guCode.length >= 3) {
            abpCode = guCode.substring(0, 3);
        } else {
            abpCode = guCode;
        }

        const createFieldDef = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.getTranslate(`table_fields.${i18nKey}`)
            };
        };

        const result =  [
            // ID
            createFieldDef('id', 'id'),

            // Название на казахском
            createFieldDef('nameKk', 'name_kk'),

            // Название на русском
            createFieldDef('nameRu', 'name_ru'),

            // Гражданская должность
            createFieldDef('civilPosition', 'civil_position'),

            // Квалификационный разряд рабочей должности
            createFieldDef('workPositionRank', 'work_position_rank'),

            // Действия
            {
                key: '_actions',
                label: ''
            }
        ];

        switch (abpCode) {
            case '252': // МВД
                result.splice(
                    3, 0,
                    // Мвд должность
                    createFieldDef('mvdPosition', 'mvd_position'),
                    // Должность по НПА для админ сотрудников Мвд
                    createFieldDef('legalActMvdPosition', 'legal_act_mvd_position'),
                );
                break;
            case '261': // Организация образования
                result.splice(
                    3, 0,
                    // Должность организации образования
                    createFieldDef('eduPosition', 'edu_position'),
                );
              result.splice(
                  3, 0,
                  // Должность по НПА
                  createFieldDef('legalActPosition', 'legal_act_position'),
              );
                break;
            default:
                result.splice(
                    3, 0,
                    // Должность по НПА
                    createFieldDef('legalActPosition', 'legal_act_position'),
                );
                break;
        }

        return result;
    }

    // noinspection JSMethodCanBeStatic
    private getColumnWidth(columnKey: string): string {
        switch (columnKey) {
            case '_actions':
            case 'data.id':
                return '0';
            /*
            case 'data.nameKk':
            case 'data.nameRu':
                return '2000px';
            case 'pprk939Pos':
            case 'uprk150Pos':
                return '750px';
            */
            default:
                return '2000px';
        }
    }

    private rowsRenderingTimeoutId: number | null = null;

    private scheduleRowsRendering() {
        this.rowsRenderingTimeoutId = setTimeout(() => {
            this.rowsRenderingTimeoutId = null;
            this.rows = new Array(...this.rows);
        });
    }

    private loading = false;

    private rows: IRow[] = [];

    private applyChange(row: IRow, change?: IChange) {
        // region Сброс данных строки
        row.changed = false;
        row.invalid = false;
        row._rowVariant = undefined;
        row.errors = {};
        // endregion

        // region Применение изменений
        if (change !== undefined) {
            if (change.nameKk !== undefined) {
                row.data.nameKk = change.nameKk;
            }
            if (change.nameRu !== undefined) {
                row.data.nameRu = change.nameRu;
            }
            if (change.funcBlock !== undefined) {
                row.data.funcBlock = change.funcBlock;
            }
            if (change.posLevel !== undefined) {
                row.inputValues.posLevel = change.posLevel;
            }
            if (change.legalActPosition !== undefined) {
                row.data.legalActPosition = change.legalActPosition;
            }
            if (change.civilPosition !== undefined) {
                row.data.civilPosition = change.civilPosition;
            }
            if (change.mvdPosition !== undefined) {
                row.data.mvdPosition = change.mvdPosition;
            }
            if (change.legalActMvdPosition !== undefined) {
                row.data.legalActMvdPosition = change.legalActMvdPosition;
            }
            if (change.workPositionRank !== undefined) {
                if (typeof change.workPositionRank === 'number') {
                    row.data.workPositionRank = change.workPositionRank;
                } else {
                    row.data.workPositionRank = null;
                }
            }
            if (change.eduPosition !== undefined) {
                row.data.eduPosition = change.eduPosition;
            }
        }
        // endregion

        // region Применение введенных пользователем значений, которые надо конвертировать
        if (row.inputValues.posLevel !== undefined) {
            const posLevel = parseFloat(row.inputValues.posLevel);
            if (posLevel.isSafeInteger) {
                row.data.posLevel = posLevel;
            } else {
                row.errors.posLevel = 'modules.budget.staffing_table.ga_department_positions.error.enter_integer_greater_than_zero';
            }
        }
        // endregion

        // region Проверка, изменена ли строка
        if (row.original === undefined) {
            row.changed = true;
        } else {
            const legalActPositionId = row.data.legalActPosition?.id;
            const originalLegalActPositionId = row.original.legalActPosition?.id;
            const civilPositionId = row.data.civilPosition?.id;
            const originalCivilPositionId = row.original.civilPosition?.id;
            const mvdPositionId = row.data.mvdPosition?.id;
            const legalActMvdPositionId = row.data.legalActMvdPosition?.id;
            const originalLegalActMvdPositionId = row.original.legalActMvdPosition?.id;
            const originalMvdPositionId = row.original.mvdPosition?.id;
            const eduPositionId = row.data.eduPosition?.id;
            const originalEduPositionId = row.original.eduPosition?.id;

            row.changed = (
                (legalActPositionId !== originalLegalActPositionId)
                || (civilPositionId !== originalCivilPositionId)
                || (mvdPositionId !== originalMvdPositionId)
                || (legalActMvdPositionId !== originalLegalActMvdPositionId)
                || (eduPositionId !== originalEduPositionId)
                || (row.data.nameKk !== row.original.nameKk)
                || (row.data.nameRu !== row.original.nameRu)
                || (row.data.funcBlock !== row.original.funcBlock)
                || (row.data.posLevel !== row.original.posLevel)
                || (row.data.workPositionRank !== row.original.workPositionRank)
            );
        }
        // endregion

        // region Валидации
        if (row.data.nameKk.trim().length === 0) {
            row.errors.nameKk = 'modules.budget.staffing_table.ga_department_positions.error.empty_field';
        }
        if (row.data.nameRu.trim().length === 0) {
            row.errors.nameRu = 'modules.budget.staffing_table.ga_department_positions.error.empty_field';
        }
        if ((row.data.posLevel !== null) && (row.data.posLevel <= 0) && (row.errors.posLevel === undefined)) {
            row.errors.posLevel = 'modules.budget.staffing_table.ga_department_positions.error.enter_integer_greater_than_zero';
        }
        (() => {
            let filledPositionFieldCount = 0;
            if (row.data.legalActPosition !== null) {
                filledPositionFieldCount++;
            }
            if (row.data.civilPosition !== null) {
                filledPositionFieldCount++;
            }
            if (row.data.mvdPosition !== null) {
                filledPositionFieldCount++;
            }
            if (row.data.legalActMvdPosition !== null) {
                filledPositionFieldCount++;
            }
            if (row.data.workPositionRank !== null) {
                filledPositionFieldCount++;
                if ((row.data.workPositionRank < 1) || (row.data.workPositionRank > 8)) {
                    // must_be_between
                    row.errors.workPositionRank = 'modules.budget.staffing_table.ga_department_positions.error.must_be_between_1_and_8';
                }
            }
            if (row.data.eduPosition !== null) {
                filledPositionFieldCount++;
            }

            if (filledPositionFieldCount > 1) {
                row.errors.legalActPosition = 'modules.budget.staffing_table.ga_department_positions.error.select_only_one_position';
                row.errors.civilPosition = 'modules.budget.staffing_table.ga_department_positions.error.select_only_one_position';
                row.errors.mvdPosition = 'modules.budget.staffing_table.ga_department_positions.error.select_only_one_position';
                row.errors.legalActMvdPosition = 'modules.budget.staffing_table.ga_department_positions.error.select_only_one_position';
                row.errors.workPositionRank = 'modules.budget.staffing_table.ga_department_positions.error.select_only_one_position';
                row.errors.eduPosition = 'modules.budget.staffing_table.ga_department_positions.error.select_only_one_position';
            } else if (filledPositionFieldCount < 1) {
                row.errors.legalActPosition = 'modules.budget.staffing_table.ga_department_positions.error.empty_field';
                row.errors.civilPosition = 'modules.budget.staffing_table.ga_department_positions.error.empty_field';
                row.errors.mvdPosition = 'modules.budget.staffing_table.ga_department_positions.error.empty_field';
                row.errors.legalActMvdPosition = 'modules.budget.staffing_table.ga_department_positions.error.empty_field';
                row.errors.workPositionRank = 'modules.budget.staffing_table.ga_department_positions.error.empty_field';
                row.errors.eduPosition = 'modules.budget.staffing_table.ga_department_positions.error.empty_field';
            }
        })();
        // endregion

        // region Установка признака "строка не корректна"
        row.invalid = (
            (row.errors.nameKk !== undefined)
            || (row.errors.nameRu !== undefined)
            || (row.errors.posLevel !== undefined)
            || (row.errors.legalActPosition !== undefined)
            || (row.errors.civilPosition !== undefined)
            || (row.errors.mvdPosition !== undefined)
            || (row.errors.legalActMvdPosition !== undefined)
            || (row.errors.workPositionRank !== undefined)
            || (row.errors.eduPosition !== undefined)
        );
        // endregion

        // region Установка варианта отображения
        if (row.invalid) {
            row._rowVariant = 'danger';
        } else if (row.changed) {
            row._rowVariant = 'success';
        } else {
            delete row._rowVariant;
        }
        // endregion

        // region Планирование перерисовки строк
        this.scheduleRowsRendering();
        // endregion
    }

    private reload() {
        if (this.loading) {
            return;
        }

        const org = this.selectedOrg;
        if (org === null) {
            console.error('Cannot reload rows - no organization selected');
            return;
        }

        const version = this.selectedVersion
        if (version === null) {
            console.error('Error: Failed to reload rows - the selected version is empty');
            return;
        }

        this.loading = true;

        Ax<Position[]>(
            {
                url: `/api/budget/staffing_table/gu-positions/version/${version.id}`
            },
            positions => {
                const rows: IRow[] = [];

                positions.forEach(position => {
                    const row: IRow = {
                        id: String(position.id),
                        data: Page.copyPosition(position),
                        original: Page.copyPosition(position),
                        changed: false,
                        invalid: false,
                        errors: {},
                        inputValues: {}
                    };
                    rows.push(row);
                });

                this.rows = rows;
            },
            error => this.toast('danger', this.getTranslate('error.cannot_load_department_positions'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }

    private addRow() {
        const org = this.selectedOrg;
        if (org === null) {
            console.error('Cannot create position - selected organization is null');
            return;
        }

        const row: IRow = {
            id: String(new Date().getTime()),
            data: {
                id: null,
                nameKk: '',
                nameRu: '',
                funcBlock: 'SCS',
                posLevel: 1,
                legalActPosition: null,
                civilPosition: null,
                mvdPosition: null,
                legalActMvdPosition: null,
                workPositionRank: null,
                eduPosition: null,
                version: null
            },
            changed: false,
            invalid: false,
            errors: {},
            inputValues: {}
        };
        this.rows.unshift(row);
        this.applyChange(row);
    }

    private save(row: IRow) {
        if (row.invalid) {
            return;
        }
        if (this.loading) {
            return;
        }

        const version = this.selectedVersion
        if (version === null) {
            console.error('Error: Failed to save gu-positions - the selected version is empty');
            return;
        }

        this.loading = true;
        Ax<number>(
            {
                url: `/api/budget/staffing_table/gu-positions/version/${version.id}`,
                method: 'POST',
                data: row.data
            },
            id => {
                row.id = String(id);
                row.data.id = id;
                row.original = Page.copyPosition(row.data);
                this.applyChange(row);

                this.toast('success', this.getTranslate('saved'), `ID - ${id}`);
            },
            error => this.toast('danger', this.getTranslate('error.cannot_save_position'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }

    private reset(row: IRow) {
        if (row.original === undefined) {
            const index = this.rows.indexOf(row);
            if (index >= 0) {
                this.rows.splice(index, 1);
            }
        } else {
            row.data = Page.copyPosition(row.original);
            this.applyChange(row);
        }
    }

    private remove(row: IRow) {
        const id = row.data.id;
        if (id === null) {
            return;
        }

        if (this.loading) {
            return;
        }

        let positionTitle: string;
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            positionTitle = row.data.nameKk;
        } else {
            positionTitle = row.data.nameRu;
        }

        this.$bvModal.msgBoxConfirm(
            this.getTranslate('removal_confirmation', [positionTitle, id]),
            {
                okVariant: 'danger'
            }
        )
            .then(value => {
                if (value === true) {
                    if (this.loading) {
                        return;
                    }

                    this.loading = true;

                    Ax(
                        {
                            url: `/api/budget/staffing_table/gu-positions/${id}`,
                            method: 'DELETE'
                        },
                        () => {
                            const index = this.rows.indexOf(row);
                            if (index >= 0) {
                                this.rows.splice(index, 1);
                            }

                            this.toast('success', this.getTranslate('removed'), `ID - ${id}`);
                        },
                        error => this.toast('danger', this.getTranslate('error.cannot_delete_department_position'), error.toString()),
                        () => {
                            this.loading = false;
                        }
                    );
                }
            });
    }
    // endregion


    // region Выбор Организации, Версии
    private selectedOrg: Org | null = store.org;

    private selectedVersion: Version | null = store.version;
    // endregion



    // region Функциональные блоки
    private get functionalBlockOptions(): Array<Comp.DropdownItemDef<Dict.Position.FunctionalBlock>> {
        const result: Array<Comp.DropdownItemDef<Dict.Position.FunctionalBlock>> = [];

        functionalBlocks.forEach(value => {
            const text = String(this.$t(`modules.budget.staffing_table._enum.functional_block.${value}`));

            const option: Comp.DropdownItemDef<Dict.Position.FunctionalBlock> = {
                value,
                text: `${value} - ${text}`
            };
            result.push(option);
        });

        return result;
    }
    // endregion


    // region Квалификационные разряды для рабочих должностей
    private get workPositionRankOptions(): Array<Comp.DropdownItemDef<number | null>> {
        const result: Array<Comp.DropdownItemDef<number | null>> = [];
        const add = (value: number | null) => {
            if (value === null) {
                result.push({
                    text: '',
                    value
                });
            } else {
                result.push({
                    text: String(value),
                    value
                });
            }
        };

        add(null);
        for (let i = 1; i <= 8; i++) { add(i); }

        return result;
    }
    // endregion
}
