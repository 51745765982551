


















































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { ListItem, ListItemPart, Overlay, PaginationPages } from '../components';
import I18n from '../I18n';
import { Comp, Dict, Utils } from '../types';
import {Values} from "vue-i18n";
import {DomUtil} from "leaflet";
import empty = DomUtil.empty;
import {IRequestParams} from "@/modules/budget/staffing-table/department-positions/types";
import {paramsSerializer} from "@/modules/budget/staffing-table/common";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRow extends Comp.TableRow<Dict.EduPosition> {}

const hasDifference = (items1: Dict.EduPosition | null, items2: Dict.EduPosition | null): boolean => {
  return items1?.id !== items2?.id;
};


const i18n = new I18n('modules.budget.staffing_table.ga_department_positions.*EduPositionSingleSelection*');
const modelChangeEvent = 'change';
const itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [
    { value: 10, text: '10' },
    { value: 25, text: '25' },
    { value: 50, text: '50' },
    { value: 100, text: '100' }
];


@Component({
    components: {
        ListItem,
        ListItemPart,
        Overlay,
        PaginationPages,
    },
})
export default class EduPositionSingleSelection extends Vue {
    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Object,
        required: false,
        default: () => null,
    })
    public readonly value!: Dict.EduPosition | null;
    // endregion


    // region Lifecycle
    private created() {
      this.selectedPosition =  {...this.value} as Dict.EduPosition

      this.$watch('selectedIds', () => {
        this.rerender()
        if (hasDifference(this.value, this.selectedPosition)) {
          this.$emit(modelChangeEvent, this.selectedPosition);
        }
      });

        // region Навигация по страницам
        this.$watch('itemsPerPage', (itemsPerPage: number) => {});

        this.$watch('page', (page: number, oldPage: number) => {
            if (page !== oldPage) {
                this.reload();
            }
        });

        this.$watch('searchRuText', () => {
            this.reload();
        });

        this.$watch('searchKkText', () => {
            this.reload();
        });

        this.$watch('searchFuncBlock', () => {
            this.reload();
        });

        this.$watch('searchEchelon', () => {
            this.reload();
        });

        this.$watch('searchLevel', () => {
            this.reload();
        });
    }
  // endregion

    private mounted() {
        this.reload();
    }
    // endregion

  // region Утилиты
  private i18n = i18n;

   private getTranslate(key: string, values?: Values): string {
    return String(this.$t(`modules.budget.staffing_table.*EduPositionSelection*.${key}`, values));
    }

   private getFieldTranslate(i18nKey: string): string {
    return this.getTranslate(`table_fields.${i18nKey}`)
   };

   private getFuncBlockText(item: Dict.EduPosition): string | null {
    if (item.funcBlock) {
      return item.funcBlock;
     }
    return null;
   }

  private getItemFullText(item: Dict.EduPosition): string {

    let result = '';


    if (result.length > 0) {
      result += ' - ';
    }

    if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
      result += item.nameKk;
    } else {
      result += item.nameRu;
    }

    return result;
  }

  private getItemText(item: Dict.EduPosition): string {
    const result = this.getItemFullText(item);
    if (result.length > 50) {
      return result.substring(0, 47) + '...';
    }
    return result;
  }

   private getEchelonText(item: Dict.EduPosition): string | null {
    if ((item.level) && (item.level.parent)) {
      return item.level.parent.code;
    }
    return null;
  }

   private getLevelText(item: Dict.EduPosition): string | null {
    if (item.level) {
      return (item.levelNumber !== null) ? item.level.code + `  (${item.levelNumber})` : item.level.code;
     }
    return null;
   }

   private toggleRowSelection(row: IRow) {
    const bool = this.selectedIds === row.data.id
    if (!bool) {
      this.selectedPosition = {...row.data}
     } else this.selectedPosition = null
   }
  // endregion

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // endregion

  private renderScheduled = false;

  private rerender() {
    if (this.renderScheduled) {
      return;
    }
    this.renderScheduled = true;
    this.$nextTick(() => {
      this.renderScheduled = false;

      this.rows.forEach(row => {
        row.selected = (this.selectedIds === row.data.id);
        if (row.selected) {
          row._rowVariant = 'primary';
        } else {
          row._rowVariant = null;
        }
      });

      this.rows = [...this.rows];
    });
  }

    private optionsEduFuncBlock = [
    { text: 'Не определён', value: '' },
    { text: 'A', value: 'A' },
    { text: 'B', value: 'B' },
    { text: 'C', value: 'C' },
    { text: 'D', value: 'D' },
  ];


    // region Навигация по страницам
    private itemsPerPage = 25;
    private itemsPerPageVariants = itemsPerPageVariants;
    private page = 0;
    private totalItems = 0;


    // region Поиск по атрибутам
    private searchRuText = '';
    private searchKkText = '';
    private searchFuncBlock = '';
    private searchEchelon = '';
    private searchLevel = null;

    // region Выбранные элементы
    private selectedPosition: Dict.EduPosition | null = null;

    private get selectedIds(): number | null {
      return this.selectedPosition?.id ?? null
  }

  // region Строки таблицы
    private get fields(): Comp.TableFieldDef[] {
      const dataField = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
        return {
          key: `data.${dataKey}`,
          label: this.getTranslate(`table_fields.${i18nKey}`)
        };
      };

      return [
      // ID
      dataField('id', 'id'),

      // Функциональный блок
      dataField('funcBlock', 'func_block'),

      // Звено
      dataField('echelon', 'echelon'),

      // Ступень
      dataField('level', 'level'),

      // Название на казахском
      dataField('nameKk', 'name_kk'),

      // Название на русском
      dataField('nameRu', 'name_ru'),
    ];
  }


    // region Данные
    private loading = false;

    // region Строки таблицы
    private rows: IRow[] = [];

    private reload() {
        if (this.loading) {
            console.error('Cannot load edu positions - another loading is running');
            return;
        }
        const params: IRequestParams = {
            'items-per-page': this.itemsPerPage,
            'page': this.page
        };

        const searchRuText = this.searchRuText.trim();
        if (searchRuText.length > 0) {
            params['search-text-ru'] = searchRuText.trim();
        }

        const searchKkText = this.searchKkText.trim();
        if (searchKkText.length > 0) {
            params['search-text-kk'] = searchKkText.trim();
        }

        const searchFuncBlock = this.searchFuncBlock.trim();
        if (searchFuncBlock.length > 0) {
            params['search-funcBlock'] = searchFuncBlock;
        }

        const searchEchelon = this.searchEchelon.trim();
        if (searchEchelon.length > 0) {
            params['search-echelon'] = searchEchelon;
        }

        const searchLevel = this.searchLevel;
        if (searchLevel !== null) {
            params['search-level'] = searchLevel;
        }


        this.loading = true;
        this.rows = [];

        Ax<Utils.PaginationList<Dict.EduPosition>>(
            {
                url: '/api/budget/staffing_table/edu-positions',
                params,
                paramsSerializer
            },
            (data) => {
                this.page = data.page;
                this.totalItems = data.itemCount;

              const rows: IRow[] = [];

              data.items.forEach(item => {
                const row: IRow = {
                  id: String(item.id),
                  data: item,
                  original: null,
                  selected: this.isItemSelected(item),
                  changed: false,
                  invalid: false,
                  _rowVariant: (this.isItemSelected(item)? 'primary' : null),
                  errors: {},
                  inputValues: {}
                };
                rows.push(row);
              });

              this.rows = rows;
            },
            (error) => { this.toast('danger', this.i18n.translate('error.cannot_load_positions'), error.toString()); },
            () => {
                this.loading = false;
            },
        );
    }

    private isItemSelected(item: Dict.EduPosition): boolean {
       return this.selectedIds === item.id
    }

  private unselect() {
      this.selectedPosition = null
  }
}
